import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import clientFragments from "./graphql/clientFragments";
import contractFragments from "./graphql/contractFragments";
import hcpFragments from "./graphql/hcpFragments";
import facilityFragments from "./graphql/facilityFragments";
import partnerFragments from "./graphql/partnerFragments";
import orderFragments from "./graphql/orderFragments";
import orderUiFragments from "./graphql/orderUiFragments";
import orderLineFragments from "./graphql/orderLineFragments";
import orderTotalsFragments from "./graphql/orderTotalsFragments";
import documentFragments from "./graphql/documentFragments";
import commentFragments from "./graphql/commentFragments";
import orderEventFragments from "./graphql/orderEventFragments";
import userFragments from "./../../UserAccounts/Queries/userFragments";
import orderAllocationsFragments from "./graphql/orderAllocationsFragments";
import orderLinesAndEvents from "./graphql/orderLinesAndEvents";
import currentUserFragments from "./graphql/currentUserFragments";
import additionalInfoFragments from "./graphql/additionalInfoFragments";
import invoiceFragments from "./graphql/invoiceFragments";
import checklistFragments from "./graphql/checklistFragments";

export const ClientObject = clientFragments.object;
export const ContractObject = contractFragments.object;
export const HealthcareProviderObject = hcpFragments.object;
export const FacilityObject = facilityFragments.object;
export const OrderLineObject = orderLineFragments.object;
export const OrderTotalsObject = orderTotalsFragments.object;
export const DocumentObject = documentFragments.object;
export const CommentObject = commentFragments.object;
export const EventObject = orderEventFragments.object;
export const UserObject = userFragments.object;
export const AdditionalInfoObject = additionalInfoFragments.object;
export const InvoiceObject = invoiceFragments.object;
export const ChecklistObject = checklistFragments.object;

export const uiStateObject = orderUiFragments.object;

export const OrderObject = PropTypes.shape({
  id: PropTypes.number.isRequired,
  webOrderNo: PropTypes.string.isRequired,
  contract: ContractObject,
  hospitalType: PropTypes.string,
  vic30DayDischarge: PropTypes.bool,
  agedCare: PropTypes.bool.isRequired,
  orderType: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  dateReceived: PropTypes.string.isRequired,
  sourceSystem: PropTypes.string.isRequired,
  fromErap: PropTypes.bool,
  invoiceNo: PropTypes.string,
  costCentre: PropTypes.string,
  cpo: PropTypes.string,
  urgent: PropTypes.bool.isRequired,
  awaitingCall: PropTypes.bool,
  assignedStaffName: PropTypes.string.isRequired,
  clinicalJustificationProvided: PropTypes.bool,
  seekPriorApproval: PropTypes.bool,
  deliveryInstructions: PropTypes.string,
  fromQuote: PropTypes.bool,
  consignment: PropTypes.bool,
  additionalInfo: PropTypes.arrayOf(AdditionalInfoObject),
  uiState: uiStateObject,
  facility: FacilityObject,
  healthcareProvider: HealthcareProviderObject,
  paymentInvoices: PropTypes.arrayOf(InvoiceObject),
  checklist: ChecklistObject,
  manualOrder: PropTypes.bool,
  requesterFirstName: PropTypes.string,
  requesterLastName: PropTypes.string,
  shipmentCarrierOptions: PropTypes.arrayOf(PropTypes.string),
  wardClientName: PropTypes.string,
  wardClientReference: PropTypes.string,
  wardClientRoom: PropTypes.string,
  adminNotes: PropTypes.string,
  priceGroup: PropTypes.string,
  priceGroupLink: PropTypes.string
});

export const CurrentUserObject = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

let cache = {};
const OrderQuery = ({ orderId, render }) => {
  return (
    <Query
      query={gqlOrderQuery(parseInt(orderId))}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (error && (error.graphQLErrors.length > 0 || !cache.order)) {
          return (
            <div className="alert" role="alert">
              <p>Error: order not accessible</p>
            </div>
          );
        }
        if (loading || (data && !data.order)) return <p>Loading...</p>;
        if (data.order && data.currentUser && data.salesReps) {
          cache = {
            order: data.order,
            currentUser: data.currentUser,
            salesReps: data.salesReps
          };
        }
        return render({
          order: cache.order,
          currentUser: cache.currentUser,
          salesReps: cache.salesReps
        });
      }}
    </Query>
  );
};

const gqlOrderQuery = id => {
  return gql` query OrderDetails {
    order(id: ${id}) {
      ...OrderFields
      client {
        ...ClientQuery
        genderOptions
        cardTypeOptions
        stateOptions
        statusOptions
        fundingOptions
      }
      healthcareProvider {
        ...HcpQuery
        providerTypeOptions
        stateOptions
      }
      facility {
        ...FacilityQuery
        stateOptions
      }
      partner {
        ...PartnerQuery
      }
      installPartner {
        id
        name
        isInternal
        isActive
      }
      ...OrderLinesAndEventsQuery
      documents {
        ...DocumentQuery
      }
      documentCategoryOptions
      documentCategoriesSyncedWithEpi
      documentAllowedMimeTypes
      comments {
        ...CommentQuery
      }
      commentsVisibleToOptions
      orderAllocations {
        ...OrderAllocationsQuery
      }
      parentOrder {
        id
        cmpOrderNumber
      }
    }
    ...CurrentUserQuery
    salesReps {
      id
      gpCode
      status
      firstName
      lastName
      name
      territory
      searchText
    }
  }
  ${orderFragments.query}
  ${clientFragments.query}
  ${hcpFragments.query}
  ${facilityFragments.query}
  ${partnerFragments.query}
  ${orderLinesAndEvents.query}
  ${documentFragments.query}
  ${commentFragments.query}
  ${orderAllocationsFragments.query}
  ${currentUserFragments.query}
  `;
};

OrderQuery.propTypes = {
  orderId: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired
};

export default OrderQuery;
