import React from "react";
import PropTypes from "prop-types";
import { CurrentUserObject } from "../Queries/OrderQuery";
import OrderUpdater from "../OrderUpdater";

class BackToInProgressBtn extends React.Component {
  state = { disabled: false };
  render() {
    const { order, currentUser } = this.props;
    return (
      <OrderUpdater
        currentUser={currentUser}
        order={order}
        render={({ updateOrder }) => (
          <button
            onClick={() => {
              this.setState({ disabled: true });
              updateOrder({
                id: order.id,
                status: "accepted"
              });
            }}
            className="button button--white"
            disabled={this.state.disabled}
          >
            Back to In Progress
          </button>
        )}
      />
    );
  }
}

BackToInProgressBtn.propTypes = {
  order: PropTypes.object,
  currentUser: CurrentUserObject.isRequired
};

export default BackToInProgressBtn;
